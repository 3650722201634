import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql, StaticQuery } from 'gatsby'

class RealisationRoll extends React.Component {
  render() {
    const { data } = this.props
    const { edges: posts } = data.allMarkdownRemark

    return (
    <div className="container-lg">
      <div className="columns is-multiline realisation">
        {posts &&
          posts.map(({ node: post }) => (

            <div className="is-parent column is-6" key={post.id}>

              <article className="tile is-child box">
                  <Link to={post.fields.slug} className="imgLink">
                  <div className="article-img"
                       style={{
                           backgroundImage: `url(${
                               !!post.frontmatter.image.childImageSharp ? post.frontmatter.image.childImageSharp.fluid.src : post.frontmatter.image
                               })`
                       }}>
                  </div>
                  </Link>
                <p>
                  <Link
                    className="title has-text-primary is-size-4"
                    to={post.fields.slug}
                  >
                    {post.frontmatter.title}
                  </Link>
                  <br/>
                  <span className="subtitle is-block">
                    {post.frontmatter.date}
                  </span>
                </p>
                <p>
                  {/*{post.excerpt}*/}
                  <br />
                  <Link className="btn" to={post.fields.slug}>
                    Voir plus
                  </Link>
                </p>
              </article>
            </div>
          ))}
      </div>
    </div>
    )
  }
}

RealisationRoll.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
}

export default () => (
  <StaticQuery
    query={graphql`
      query RealisationRollQuery {
        allMarkdownRemark(
          sort: { order: DESC, fields: [frontmatter___date] }
          filter: { frontmatter: { templateKey: { eq: "realisation" } } }
        ) {
          edges {
            node {
              excerpt(pruneLength: 400)
              id
              fields {
                slug
              }
              frontmatter {
                title
                templateKey
                date(formatString: "D MMMM YYYY", locale: "fr" )
                description
                image {
                  childImageSharp {
                    fluid(maxWidth: 1680, quality: 80) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}
    render = {
        (data, count) => <RealisationRoll data = {
          data
        }
        count = {
          count
        }
        />}
  />
)
