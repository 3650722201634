import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import Content, { HTMLContent } from '../components/Content'
import RealisationRoll from '../components/RealisationRoll'


export const LabEffentiTemplate = ({ title, content, contentComponent }) => {
  const PageContent = contentComponent || Content

  return (
    <div className="wrapper lab-bg">
        <div className="container">
          <section className="main-ctn lab-effenti">

            <h1 className="title">
              {title}
            </h1>

            <PageContent className="content" content={content} />

            <RealisationRoll />
          </section>
        </div>
      <div className="spacer-red triangle-red-left is-relative"></div>
  </div>
  )
}

LabEffentiTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
}

const LabEffenti = ({ data }) => {
  const { markdownRemark: post } = data
  return (
    <Layout>
      <LabEffentiTemplate
        contentComponent={HTMLContent}
        title={post.frontmatter.title}
        content={post.html}
      />
    </Layout>
  )
}

LabEffenti.propTypes = {
  data: PropTypes.object.isRequired,
}

export default LabEffenti

export const labEffentiQuery = graphql`
  query LabEffenti($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
      }
    }
  }
`
